import React, { useContext, useEffect, useMemo, useRef } from "react"
import { CarCareTipsTemplate } from "./templates"
import { graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import { SEO } from "../components/atoms/SEO"
import RelatedArticles from "../components/organisms/CarCareTips/RelatedArticles"
import { Brochure, ToyotaCare } from "../components/organisms/Series"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import Header from "../components/molecules/Heros/Header"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import useDealers from "../hooks/useDealers"
import { LocationContext } from "../contexts/Location"
import { LanguageContext } from "../contexts/Language"
import { windowExists } from "../helpers"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"

const CarCareTipsPage: React.FC<CarCareTipsTemplate> = ({
  data,
  location,
  pageContext,
}: any) => {
  const { page } = data
  const articleRoutes = data.articleRoutes.nodes.filter(
    (node: any) => node.page?._type === "page.article"
  )

  const { _ } = useContext(LanguageContext)

  //Tealium

  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()

  useEffect(() => {
    if (windowExists() && location?.hash?.includes("#dealersNearYou")) {
      const dealersNearYouEl = document.getElementById("dealersNearYou")
      const dealersNearYouPosition = dealersNearYouEl.getBoundingClientRect()
      window.scroll({
        top:
          dealersNearYouPosition.top +
          window.innerHeight * 0.85 +
          window.scrollY,
        behavior: "smooth",
      })
    }
  }, [])

  useEffect(() => {
    if (pageload.current && isOutOfArea) {
      pageload.current = false
      handleTealView({
        page_name: "car care tips",
        tealium_event: "view",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
        page_type: "parts service",
      })
    }

    const dealerInfo = getDealerInfo()
    // Fire only on inital page load, and not every time dealers is updated
    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        tealium_event: "view ",
        page_name: "car care tips",
        search_results: dealerInfo.searchResults,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
        page_type: "parts service",
      })
    }
  }, [dealers, isOutOfArea])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <SanitySections
        data={page}
        translations={pageContext.translations}
        categories={data.categories}
        articleRoutes={articleRoutes}
      />
    </Layout>
  )
}

const SanitySections = ({
  data,
  categories,
  translations,
  articleRoutes,
}: any): JSX.Element => {
  const retentionSalesEventData = data?.content?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null

  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "header":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }

            return (
              <Header
                section={section}
                aria-label="Car Care Tips Hero Section"
              />
            )
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return <PartAndServiceHero {...section} />
          case "relatedArticles":
            return (
              <RelatedArticles
                {...section}
                translations={translations}
                articleRoutes={articleRoutes}
              />
            )
          case "imageWithText":
            return <ToyotaCare {...section} />
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="default"
                {...section}
                ctaAnalyticsId="schedule service"
              />
            )
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "brochure":
            return <Brochure {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query CarCareTipsPageQuery($id: String) {
    page: sanityPageCarCareTips(id: { eq: $id }) {
      id
      language
      _type
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...HeaderData
        ...RelatedArticlesData
        ...ToyotaCareData
        ...DealersNearYouData
        ...CallToActionBoxData
        ...ImageBlocksWithDescriptionData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...PartsAndServiceHeroData
        ...BrochureData
      }
    }
    categories: allSanityArticleCategory {
      nodes {
        category
      }
    }
    articleRoutes: allSanityRoute {
      nodes {
        page {
          ... on SanityPageArticle {
            _type
            _id
          }
        }
        slug {
          current
        }
      }
    }
  }
`

export default CarCareTipsPage
