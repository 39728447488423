import React, { useContext, useMemo } from "react"
import { RelatedArticlesProps } from "./CarCareTips.d"
import tw from "twin.macro"
import BlockContent from "../../atoms/BlockContent"
import { Image } from "../../atoms/Image"
import { ThreeArrowAnimation } from "../../molecules/ThreeArrowAnimation"
import { Link } from "../../atoms/Link"
import { LanguageContext } from "../../../contexts/Language"
import { css } from "@emotion/react"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Layout } from "../../atoms/Layout"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const RelatedArticles: React.FC<RelatedArticlesProps> = ({
  articles,
  layout,
  articleRoutes,
  translations,
}) => {
  const { language } = useContext(LanguageContext)

  // Refactor of old code to get routes for translated documents.
  // Since we are no longer able to get the referenced document from the artice,
  // we need to get the translations metadata to get the correct route from matching _id.
  const articlesWithRoutes = articles.map((article: any) => {
    const route = articleRoutes.find((route: any) => {
      if (language === "es") {
        const esId = translations.find(item => {
          return item.es?._id === article?._id
        })
        const routePageId = esId?.en?._id
        return (
          route?.page?._id === routePageId &&
          !route.slug?.current?.includes("partsandservice")
        )
      } else {
        return (
          route.page?._id === article._id &&
          !route.slug?.current?.includes("partsandservice")
        )
      }
    })
    return { ...article, route }
  })

  return (
    <section
      aria-label="Article Section"
      css={[
        tw`grid grid-cols-1 h-auto max-w-desktop mx-auto relative px-6`,
        tw`lg:(grid-cols-12 auto-rows-min px-10 mb-0 gap-10)`,
      ]}
    >
      {layout === "left" ? (
        <>
          <FeaturedArticleLeft articles={articlesWithRoutes} />
          <hr
            css={tw`col-span-12 mb-8 h-[5px] bg-gray-200 border-0 dark:bg-gray-700`}
          />
        </>
      ) : (
        <>
          <FeaturedArticleRight articles={articlesWithRoutes} />
          <hr
            css={tw`col-span-12 mb-8 h-[5px] bg-gray-200 border-0 dark:bg-gray-700`}
          />
        </>
      )}
    </section>
  )
}

const FeaturedArticleLeft = ({ articles, index }: any) => {
  const { _, language } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <>
      {/* <!-- First Article --> */}
      <article
        arial-label="Article"
        css={[
          tw`text-lg mb-8 col-span-12 col-start-1`,
          tw`lg:(text-lg mb-8 col-span-7 col-start-1)`,
        ]}
      >
        {/* USE the layout prop to determine if large article should be on left or right */}
        <div css={[tw`py-8`]}>
          <Image
            transparentPreview
            imageData={articles[0]?.featuredImage?.image}
            css={[tw`h-[420px] w-full bg-cover`, tw`!h-auto bg-cover w-full`]}
            objectFit="cover"
          />
          <div
            css={[tw`text-xl uppercase text-red-500 mt-8 mb-4 font-semibold`]}
          >
            {articles[0]?.categories[0].category}
          </div>
          <Link
            to={`/${articles[0]?.route?.slug?.current}`}
            className="group"
            css={[tw`relative text-sm mt-4 font-semibold`]}
            onClick={() => {
              trackTealEvent({
                tealium_event: "article_click",
                link_href: `/${articles[0]?.route?.slug?.current}`,
              })
            }}
            analytics-id={`article:articles:left:1`}
          >
            <h2
              css={[tw`font-semibold text-2xl w-full mb-8 lg:(w-4/5 text-5xl)`]}
            >
              {parseDisclaimerBlocks(articles[0]?.title, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h2>
            <div css={[tw`text-base mb-4`, tw`lg:(w-4/5)`]}>
              <BlockContent data={articles[0]?.excerpt} />
            </div>
            {articles.length > 0 && articles[0]?.route && (
              <>
                <span>{_("Read Article")}</span>
                <span css={[tw`inline absolute bottom-[1.8rem]`]}>
                  <ThreeArrowAnimation />
                </span>
              </>
            )}
          </Link>
        </div>
      </article>
      <div
        css={[
          tw`text-lg mb-8 col-span-1`,
          tw`lg:(text-lg mb-8 col-span-5 col-start-8)`,
        ]}
      >
        {/* <!-- Remaining Articles -->*/}
        {articles.slice(1).map((article: any, index: number) => {
          if (!article.route) return null
          return (
            <div
              css={[
                tw`py-8 border-b`,
                css`
                  :last-of-type {
                    ${tw`border-none`}
                  }
                `,
              ]}
            >
              {/* USE the layout prop to determine if large article should be on left or right */}
              <Image
                transparentPreview
                imageData={article.featuredImage?.image}
                css={[tw`h-auto w-full bg-cover`]}
                objectFit="cover"
              />
              {article.categories.length > 0 && (
                <div
                  css={[
                    tw`text-xl uppercase text-red-500 mt-8 mb-4 font-semibold`,
                  ]}
                >
                  {article.categories[0].category}
                </div>
              )}
              <Link
                to={`/${article?.route?.slug?.current}`}
                className="group"
                css={[tw`relative text-sm mt-4 font-semibold`]}
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "article_click",
                    link_href: `/${article?.route?.slug?.current}`,
                  })
                }}
                analytics-id={`article:articles:right:${index + 1}`}
              >
                <h2 css={[tw`font-semibold text-3xl w-full mb-10 lg:(w-4/5)`]}>
                  {parseDisclaimerBlocks(article.title, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </h2>
                <div css={[tw`text-base mb-4`, tw`lg:(w-4/5)`]}>
                  <BlockContent data={article.excerpt} />
                </div>
                {article?.route && (
                  <>
                    <span>{_("Read Article")}</span>
                    <span css={[tw`inline absolute bottom-[1.8rem]`]}>
                      <ThreeArrowAnimation />
                    </span>
                  </>
                )}
              </Link>
            </div>
          )
        })}
      </div>
    </>
  )
}

const FeaturedArticleRight = ({ articles, index }: any) => {
  const { _, language } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <>
      <article
        arial-label="Article"
        css={[
          tw`text-lg mb-8 col-span-12 col-start-1`,
          tw`lg:(text-lg mb-8 col-span-7 col-start-6)`,
        ]}
      >
        <div css={[tw`py-8`]}>
          <Image
            transparentPreview
            imageData={articles[0]?.featuredImage?.image}
            css={[tw`h-[420px] w-full bg-cover`, tw`!h-auto bg-cover w-full`]}
            objectFit="cover"
          />
          <div
            css={[tw`text-xl uppercase text-red-500 mt-8 mb-4 font-semibold`]}
          >
            {articles[0]?.categories[0].category}
          </div>
          <Link
            to={`/${articles[0]?.route?.slug?.current}`}
            className="group"
            css={[tw`relative text-sm mt-4 font-semibold`]}
            onClick={() => {
              trackTealEvent({
                tealium_event: "article_click",
                link_href: `/${articles[0]?.route?.slug?.current}`,
              })
            }}
            analytics-id={`article:articles:right${index + 1}`}
          >
            <h2
              css={[tw`font-semibold text-2xl w-full mb-8 lg:(w-4/5 text-5xl)`]}
            >
              {parseDisclaimerBlocks(articles[0]?.title, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h2>
            <div css={[tw`text-base mb-4`, tw`lg:(w-4/5)`]}>
              <BlockContent data={articles[0]?.excerpt} />
            </div>
            {articles.length > 0 && articles[0]?.route && (
              <>
                <span>{_("Read Article")}</span>
                <span css={[tw`inline absolute bottom-[1.8rem]`]}>
                  <ThreeArrowAnimation />
                </span>
              </>
            )}
          </Link>
        </div>
      </article>

      <div
        css={[
          tw`text-lg mb-8 col-span-1 col-start-12 row-start-2`,
          tw`lg:(text-lg mb-8 col-span-5 row-start-1 col-start-1)`,
        ]}
      >
        {/* <!-- Remaining Articles -->*/}
        {articles.slice(1).map((article: any, index: number) => {
          return (
            <div
              css={[
                tw`py-8 border-b`,
                tw`py-8 border-b`,
                css`
                  :last-of-type {
                    ${tw`border-none`}
                  }
                `,
              ]}
            >
              <Image
                transparentPreview
                imageData={article.featuredImage?.image}
                css={[tw`h-auto w-full bg-cover`]}
                objectFit="cover"
              />
              {article.categories.length > 0 && (
                <div
                  css={[
                    tw`text-xl uppercase text-red-500 mt-8 mb-4 font-semibold`,
                  ]}
                >
                  {article.categories[0].category}
                </div>
              )}
              <Link
                to={`/${article?.route?.slug?.current}`}
                className="group"
                css={[tw`relative text-sm mt-4 font-semibold`]}
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "article_click",
                    link_href: `/${article?.route?.slug?.current}`,
                  })
                }}
                analytics-id={`article:articles:${index + 1}`}
              >
                <h2 css={[tw`font-semibold text-3xl w-full mb-10 lg:(w-4/5)`]}>
                  {parseDisclaimerBlocks(article.title, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </h2>
                <div css={[tw`text-base mb-4`, tw`lg:(w-4/5)`]}>
                  <BlockContent data={article.excerpt} />
                </div>
                {article?.route && (
                  <>
                    <span>{_("Read Article")}</span>
                    <span css={[tw`inline absolute bottom-[1.8rem]`]}>
                      <ThreeArrowAnimation />
                    </span>
                  </>
                )}
              </Link>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default RelatedArticles
